import React, {Fragment, useContext, useEffect, useState} from 'react';
import {Form, Input, Button, Modal, Select, message, notification} from 'antd';
import {PartnerAccountAPI, ProtectionDTO} from "@backend-lib";
import {AxiosContext, AxiosContextType} from "@front-component";
import {ModalContextReady} from "../ModalContextReady";

interface Subscriber {
    id: string;
    name: string;
    emails: string[];
    subscription: string;
    keywords: string[];
}

const { Option } = Select;

export function EditAccount(props:{protection:ProtectionDTO,handleModalOk:any}) {
    const {api} = useContext<AxiosContextType>(AxiosContext);
    const [form] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);


    const handleFormSubmit = async (values: Subscriber) => {
        // Handle form submission logic here
        console.log('Form submitted:', values);

        try {
            await PartnerAccountAPI.getInstance(api!).editAccount(values)
            // Show confirmation modal
            setIsModalVisible(true);
        } catch (e: any) {
            console.error("error",e)
            notification.error({
                message: "Unable to edit subscriber",
                description: <Fragment>{e.message}</Fragment>,
            });
        }
    };

    const handleSubscriptionChange = (value: string) => {
        // Reset keywords field when subscription changes
        form.setFieldsValue({ keywords: [] });
    };

    const validateEmails = (_: any, value: string[]) => {
        // Validate that all emails are valid
        const emailRegex = /^[\w\+-.]+@([\w-]+\.)+[\w-]{2,4}$/;

        for (const email of value) {
            if (!emailRegex.test(email)) {
                return Promise.reject('Invalid email format');
            }
        }

        return Promise.resolve();
    };

    const validateKeywords = (_: any, value: string[],subscription: string) => {
        // Validate keyword limit based on subscription
        //const subscription = form.getFieldValue('subscription');
        const keywordLimit = subscription.includes("partner_3") ? 3 : 10;

        if (value.length > keywordLimit) {
            return Promise.reject(`Please enter up to ${keywordLimit} keyword(s)`);
        }

        return Promise.resolve();
    };

    return (
        <Form form={form} onFinish={handleFormSubmit} initialValues={{
            id: props.protection.account?.id,
            name: props.protection.account?.name,
            emails: props.protection.account?.users?.map(u => u.email),
            subscription: (props.protection as any).account?.partnerSubscription,
            keywords: props.protection.keywordsCriteria?.map(k => k.text),
        }} layout="vertical">
            <Form.Item
                name="id"
                hidden={true}
            >
                <Input placeholder=""/>
            </Form.Item>
            <Form.Item
                name="name"
                label="Subscriber Name or Company"
                rules={[{required: true, message: 'Please enter a name'}]}
            >
                <Input placeholder="Apple"/>
            </Form.Item>

            <Form.Item
                name="emails"
                label={<b>Enter The Email Associated With The Google Ads Account</b>}
                rules={[
                    {required: true, message: 'Please enter emails'},
                    {validator: validateEmails},
                ]}
            >
                <Select
                    mode="tags"
                    placeholder="tim@apple.com, steve@apple.com"
                    style={{width: '100%'}}
                    tokenSeparators={[',']}
                />
            </Form.Item>

            <Form.Item
                name="subscription"
                label="Select subscription"
                rules={[{required: true, message: 'Please select a subscription'}]}
            >
                {<Select
                    disabled={true}
                    placeholder="Select subscription"
                    onChange={handleSubscriptionChange}
                >
                    <Option value="getrank_partner_3_monthly_1">3 keywords</Option>
                    <Option value="getrank_partner_10_monthly_1">10 keywords</Option>
                </Select>}
            </Form.Item>

            <Form.Item
                name="keywords"
                label="Enter keywords for brand monitoring"
                rules={[
                    ({getFieldValue}) => ({
                        validator: (_, value) => {
                            const subscription = getFieldValue('subscription');
                            return validateKeywords(null, value, subscription);
                        },
                    }),
                ]}
                dependencies={['subscription']}
            >
                <Select
                    mode="tags"
                    placeholder="key1, key2, key3"
                    style={{width: '100%'}}
                    tokenSeparators={[',']}
                />
            </Form.Item>

            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <Form.Item shouldUpdate>
                {() => (
                    <Button block type="primary" htmlType="submit" disabled={!form.getFieldValue('subscription')}>
                        Edit Subscriber
                    </Button>
                )}
            </Form.Item>

            <ModalContextReady
                open={isModalVisible}
                onCancel={props.handleModalOk}
                onOk={props.handleModalOk}
                cancelButtonProps={{style: {display: 'none'}}}
                title="Confirmation"
            >
                <p>Congratulations! Subscriber edited</p>
            </ModalContextReady>
        </Form>
    );
};